var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-access-points-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.security",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.security),function(security){return _c('v-chip',{key:security.id,attrs:{"small":""}},[_vm._v(_vm._s(security.firstName)+" "+_vm._s(security.lastName))])}),1)]}},{key:"item.main",fn:function(ref){
var item = ref.item;
return [(item.main)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.accessControlManagement)?_c('v-btn',{attrs:{"icon":"","to":{ name: 'panel.event.view.accessControl.edit', params: { acid: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)]}}]),model:{value:(_vm.state.selected),callback:function ($$v) {_vm.$set(_vm.state, "selected", $$v)},expression:"state.selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }