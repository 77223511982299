




































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAuthPermission from "@/use/authPermissions";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "name",
          text: root.$tc("panel.event.accessControl.list.controlPoint"),
        },
        {
          value: "security",
          text: root.$tc("panel.event.accessControl.list.security"),
          sortable: false,
        },
        {
          value: "main",
          text: root.$tc("panel.event.accessControl.list.mainPoint"),
          sortable: false,
        },
        { value: "actions", text: "", sortable: false, align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchControlPoints = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/control-point`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { controlPoints, total } }) => {
          state.items = controlPoints;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchControlPoints);

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`control-point/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchControlPoints();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const { accessControlManagement } = useAuthPermission({ root });

    return {
      state,
      accessControlManagement,
      deleteItem,
    };
  },
});
